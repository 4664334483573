import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexBetween,
  FlexCol,
  DsiMobileNavigationItem as MobileNavItem,
  DsiItemType as ItemType,
  TextP,
  DsiMobileNavSubItem as MobileNavSubItem,
  DsiSvgIcon as SvgIcon,
  DsiIconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { __, __r } from 'react-i18n';
import { prop } from '../../utilities';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { resolveCategoryName } from '../../utilities/category';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  landingPages: any;
  closeMenu: () => void;
  lang: string;
  navArticles: any;
  byId: any;
  showActionLink: boolean;
}

function MobileNavigation({
  items,
  landingPages,
  closeMenu,
  lang,
  navArticles,
  byId,
  showActionLink,
}: Props) {
  const [level, setLevel] = useState<number>(1);
  const [title, setTitle] = useState<string>('Menu');
  const [currentItems, setCurrentItems] = useState<any>(items);
  const [previous, setPrevious] = useState<
    Array<{ items: any; title: string }>
  >([]);

  const upLevel = (id: number = -1) => {
    const currentLvl = level;

    if (currentLvl === 1) {
      setPrevious([
        {
          items,
          title: 'Menu',
        },
      ]);
    }

    const item = currentItems[id];
    if (item) {
      const newItems = currentItems[id].children;
      const newTitle = resolveCategoryName(currentItems[id]);
      const newPrevious = previous;
      newPrevious.push({
        items: currentItems,
        title,
      });

      setPrevious(newPrevious);
      setLevel(level + 1);
      setTitle(newTitle);
      setCurrentItems(newItems);
    }
  };

  const downLevel = () => {
    const currentLvl = level;

    if (currentLvl > 1) {
      setLevel(level - 1);
      if (previous && previous.length) {
        setTitle(previous[previous.length - 1].title);
        setCurrentItems(previous[previous.length - 1].items);

        const newPrevious = [...previous];
        newPrevious.pop();
        setPrevious(newPrevious);
      }
    }
  };

  const isFirstLevel = level === 1;
  return (
    <Wrapper>
      <IconTitleWrapper onClick={downLevel}>
        {isFirstLevel ? (
          <HigherLevelIconWrapper onClick={closeMenu}>
            <SvgIcon
              icon={IconType.closeThin}
              alt={__('Zavrieť menu')}
              viewBox={'0 0 24 24'}
              width={24}
              height={24}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              fill="##414141"
            />
          </HigherLevelIconWrapper>
        ) : (
          <IconWrapper onClick={downLevel}>
            <SvgIcon
              icon={IconType.backThin}
              alt={__('Späť')}
              viewBox={'0 0 24 24'}
              width={24}
              height={24}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              fill="##414141"
            />
          </IconWrapper>
        )}
        <Title isFirstLevel={isFirstLevel}>{title}</Title>
        {level > 1 && (
          <HigherLevelIconWrapper onClick={closeMenu}>
            <SvgIcon
              icon={IconType.closeThin}
              alt={__('Zavrieť menu')}
              viewBox={'0 0 24 24'}
              width={24}
              height={24}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              fill="##414141"
            />
          </HigherLevelIconWrapper>
        )}
      </IconTitleWrapper>
      {renderContentItems(
        level,
        upLevel,
        closeMenu,
        currentItems,
        landingPages,
        lang,
        navArticles,
        byId,
        showActionLink,
      )}
    </Wrapper>
  );
}

const renderContentItems = (
  level,
  upLevel,
  closeMenu,
  currentItems,
  landingPages,
  lang,
  navArticles,
  byId,
  showActionLink,
) => {
  const rightIconSrc = '/images/icons/menu_arrow_right.svg';

  if (level === 1) {
    return (
      <MenuItemsWrapper>
        {currentItems &&
          currentItems.map((category, i) => {
            if (category.category_number === 'BAZAR' && lang === 'cz') {
              return null;
            }
            return (
              <MobileNavSubItem
                key={category.category_id}
                icon={
                  category.category_number === 'BAZAR'
                    ? IconType.recycled
                    : undefined
                }
                imgSrc={
                  category.image
                    ? getImagePath(
                        category.image,
                        {
                          width: 24,
                          height: 24,
                        },
                        false,
                        true,
                        true,
                      )
                    : '/images/icons/placeholder.svg'
                }
                showArrow={category.children.length > 0}
                arrowSrc={rightIconSrc}
                name={
                  prop(category, 'publish.0.content.json_content.shortname') ||
                  prop(category, 'publish.0.category_name') ||
                  category.category_name
                }
                url={prop(category, 'publish.0.url') || category.url}
                weburl={
                  category.weburl
                    ? resolveCategoryUrl(
                        category.redirect_category_id,
                        prop(
                          byId,
                          `${category.redirect_category_id}.publish.0.url`,
                        ) || category.weburl,
                        'CATEGORYTEXT/URL',
                      )
                    : null
                }
                id={category.category_id}
                onClickLink={closeMenu}
                isFirstLevel={true}
                // // tslint:disable-next-line:jsx-no-lambda
                onClick={() => upLevel(i)}
                isDsi={true}
              />
            );
          })}

        {navArticles.map((navArticle, i) => {
          if (
            prop(navArticle, 'uniqid', '') &&
            prop(navArticle, 'uniqid', '').includes('AKCIE')
          ) {
            return (
              <MobileNavItem
                onClick={closeMenu}
                url={`/${navArticle.url}`}
                type={ItemType.ACTION}
                title={navArticle.name}
              />
            );
          }

          return null;
        })}

        {landingPages?.length
          ? landingPages.map(landingPage => {
              return (
                <MobileNavItem
                  onClick={closeMenu}
                  url={`/${landingPage.url.replace(/^\/|\/$/g, '')}`}
                  type={ItemType.ACTION}
                  title={landingPage.name}
                />
              );
            })
          : []}

        {navArticles.map(article => {
          if (
            prop(article, 'uniqid', '') &&
            prop(article, 'uniqid', '').includes('AKCIE') &&
            showActionLink
          ) {
            return null;
          }
          return (
            <MobileNavItem
              onClick={closeMenu}
              linkUrl={article.link}
              url={`/${article.url}`}
              type={ItemType.TERTIARY}
              title={article.name}
            />
          );
        })}
      </MenuItemsWrapper>
    );
  }
  if (level > 1) {
    return (
      currentItems &&
      currentItems.map((category, i) => {
        return (
          <MobileNavSubItem
            key={category.category_id}
            imgSrc={
              category.image
                ? getImagePath(
                    category.image,
                    {
                      width: 24,
                      height: 24,
                    },
                    false,
                    true,
                    true,
                  )
                : '/images/icons/placeholder.svg'
            }
            showArrow={category.children.length > 0}
            arrowSrc={rightIconSrc}
            name={
              prop(category, 'publish.0.content.json_content.shortname') ||
              prop(category, 'publish.0.category_name') ||
              category.category_name
            }
            url={prop(category, 'publish.0.url') || category.url}
            weburl={
              category.weburl
                ? resolveCategoryUrl(
                    category.redirect_category_id,
                    prop(
                      byId,
                      `${category.redirect_category_id}.publish.0.url`,
                    ) || category.weburl,
                    'CATEGORYTEXT/URL',
                  )
                : null
            }
            id={category.category_id}
            onClickLink={closeMenu}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={
              category.children.length === 0 ? () => {} : () => upLevel(i)
            }
            isDsi={true}
          />
        );
      })
    );
  }

  return null;
};

const Wrapper = styled.div`
  width: 100%;
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  /* justify-content: flex-start; */
  padding: 0 ${rem(16)};

  padding-left: 0;
  padding-right: 0;
`;

const IconWrapper = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    vertical-align: baseline;
  }
`;

const HigherLevelIconWrapper = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    vertical-align: baseline;
  }
`;

const MenuItemsWrapper = styled(FlexCol)``;

const Title = styled(TextP)<{ isFirstLevel }>`
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: ${({ isFirstLevel }) => (isFirstLevel ? rem(0) : rem(-4))};
  display: inline-block;
  flex: 1 1 auto;
`;

export { MobileNavigation };
