import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { __, __r } from 'react-i18n';
// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';
import UrlMapper from './containers/UrlMapper/UrlMapper';
import SearchResultsContainer from './containers/Search/SearchResults';
import ProductContainer from './containers/Product/Product';
// import LoginPage from './containers/Auth/LoginPage';
// import RegisterPage from './containers/Auth/RegisterPage';
// import ForgotPassword from './containers/Auth/ForgotPassword';
import CMSArticles from './containers/CMS/CMSArticles';
import MyAccount from './containers/MyAccount/MyAccount';
import MyOrders from './containers/MyAccount/MyOrders';
import MyInfoContainer from './containers/MyAccount/MyInfo';
import MyDeliveryAddressesContainer from './containers/MyAccount/MyDeliveryAddresses';
import AccountManagementContainer from './containers/MyAccount/AccountManagement';
import ChangePassword from './containers/MyAccount/ChangePassword';
import API from './services/API';
import Activation from './containers/MyAccount/Activation';
import NewPassword from './containers/MyAccount/NewPassword';
import Category from './containers/Category/Category';
import SaleoutCategory from './containers/Category/SaleoutCategory';
import ActionContainer from './containers/Action/Action';
import MyDashboard from './containers/MyAccount/MyDashboard';
import CartContainer from './containers/Cart/Cart';
import FinishedOrder from './containers/Cart/FinishedOrder';
// import { cartRoutes } from './utilities/cart';
import MyInvoices from './containers/MyAccount/MyInvoices';
import MyPayables from './containers/MyAccount/MyPayables';
import Backorder from './containers/MyAccount/Backorder';
import MyFavorites from './containers/MyAccount/MyFavorites';
import Watching from './containers/MyAccount/Watching';
import MyPurchase from './containers/MyAccount/MyPurchase';
import MyOrderDetail from './containers/MyAccount/MyOrderDetail';
import ReactGA from 'react-ga4';
import ActionsContainer from './containers/Actions/Actions';
import LoginPage from './containers/Auth/LoginPage';
import RegisterPage from './containers/Auth/RegisterPage';
import ForgotPassword from './containers/Auth/ForgotPassword';
import TagResults from './containers/TagResults/TagResults';
import ContactForm from './containers/CMS/ContactForm';
import OrderCancelRequest from './containers/OrderCancel/OrderCancelRequest';
import OrderCancel from './containers/OrderCancel/OrderCancel';
import { extractRustPart } from './configureTrans';
import ManagerReport from './containers/ManagerReport/ManagerReport';
import MySettings from './containers/MyAccount/MySettings';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const onUpdate = () => {
  if (window.location.hash.length === 0) {
    scrollToTop();
  }

  setTimeout(() => {
    ReactGA.set({ page: window.location.pathname });
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send('pageview');
  }, 2500);
};

const renderActivation = props => {
  return <Activation API={API} params={props.params} />;
};

const blogSubId = 135;
const kitchenSubId = 195;
const audioSubId = 191;
const homeSubId = 193;

const Routes = (props: any) => {
  return (
    <Router onUpdate={onUpdate} history={props.history}>
      <Route path="/manager" component={ManagerReport} />
      <Route path="/" component={App}>
        <Route
          path={__r('routes:vyhladavanie', '/vyhladavanie')}
          component={SearchResultsContainer}
        />
        <Route
          path={`${__r('routes:znacka', '/znacka')}/:brand_id/:brand_name(/**)`}
          component={SearchResultsContainer}
        />

        <Route
          path={`${__r('routes:znacka', '/znacka')}/:brandUrl`}
          component={SearchResultsContainer}
        />

        <Route
          path={`${__r(
            'routes:produkt',
            '/produkt',
          )}/:product_id/:product_url(/**)`}
          component={ProductContainer}
        />
        <Route
          path={'/produkt/:product_id/:product_url'}
          component={ProductContainer}
        />

        {[
          `${__r(
            'routes:kategoria',
            '/kategoria',
          )}/:category_id/:category_url(/**)`,
        ].map((path, index) => (
          <Route path={path} component={Category} key={index} />
        ))}

        {[`${__r('routes:kategoria', '/kategoria')}/:category_url(/**)`].map(
          (path, index) => (
            <Route path={path} component={Category} key={index} />
          ),
        )}

        <Route
          path={__r('routes:vypredaj', '/vypredaj')}
          component={SaleoutCategory}
        />
        <Route
          path={__r('routes:akcie', '/akcie')}
          component={match => <ActionsContainer />}
        />
        {/*
        <Route
          path={`${__r('routes:akcie', '/akcie')}/:id/:slug(/**)`}
          component={ActionContainer}
          /> */}
        <Route
          path={__r('routes:prihlasenie', '/prihlasenie')}
          component={LoginPage}
        />
        <Route
          path={__r('routes:registracia', '/registracia')}
          component={RegisterPage}
        />
        <Route
          path={__r('routes:zabudnute-heslo', '/zabudnute-heslo')}
          component={ForgotPassword}
        />
        <Route path={__r('routes:moj-ucet', '/moj-ucet')} component={MyAccount}>
          <Route
            path={__r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard')}
            component={MyDashboard}
          />
          <Route
            path={__r('routes:moj-ucet/faktury', '/moj-ucet/faktury')}
            component={MyInvoices}
          />
          <Route
            path={__r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky')}
            component={MyOrders}
          />
          <Route
            path={__r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky')}
            component={MyPayables}
          />
          <Route
            path={__r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy')}
            component={MyPurchase}
          />
          <Route
            path={__r('routes:moj-ucet/backorder', '/moj-ucet/backorder')}
            component={Backorder}
          />
          <Route
            path={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}
            component={MyFavorites}
          />
          <Route
            path={__r('routes:moj-ucet/strazny-pes', '/moj-ucet/strazny-pes')}
            component={Watching}
          />
          <Route
            path={__r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje')}
            component={MyInfoContainer}
          />
          <Route
            path={__r(
              'routes:moj-ucet/moje-dodacie-adresy',
              '/moj-ucet/moje-dodacie-adresy',
            )}
            component={MyDeliveryAddressesContainer}
          />
          <Route
            path={`${__r(
              'routes:moj-ucet/objednavka',
              '/moj-ucet/objednavka',
            )}/:orderPublicId`}
            component={MyOrderDetail}
          />
          <Route
            path={__r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov')}
            component={AccountManagementContainer}
          />
          <Route
            path={__r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla')}
            component={ChangePassword}
          />
          <Route
            path={__r('routes:moj-ucet/nastavenia', '/moj-ucet/nastavenia')}
            component={MySettings}
          />
          <IndexRoute component={MyDashboard} />
        </Route>
        <Route path={__r('routes:kosik', '/kosik')} component={CartContainer} />
        <Route
          path={`${__r(
            'routes:dokoncena-objednavka',
            '/dokoncena-objednavka',
          )}/:orderPublicId`}
          component={FinishedOrder}
        />

        <Route
          path={`${__r(
            'routes:zrusenie-objednavky-poziadavka',
            '/zrusenie-objednavky-poziadavka',
          )}/:orderPublicId`}
          component={OrderCancelRequest}
        />

        <Route
          path={`${__r(
            'routes:zrusenie-objednavky',
            '/zrusenie-objednavky',
          )}/:orderPublicId/:token`}
          component={OrderCancel}
        />

        <Route
          path={'/nove-heslo/:token' || `${__('routes:nove-heslo')}/:token`}
          component={NewPassword}
        />
        <Route
          path={__r('routes:blog', '/blog')}
          component={CMSArticles}
          props={{
            name: 'Blog',
          }}
        />
        <Route
          path={__r(
            'routes:vseobecne-podmienky/kontaktny-formular',
            '/vseobecne-podmienky/kontaktny-formular',
          )}
          component={ContactForm}
        />
        {/* <Route
          path={__r('routes:clanky/audio-video', '/clanky/audio-video')}
          component={CMSArticles}
          props={{
            sitemapId: audioSubId,
            name: 'Audio',
          }}
        />
        <Route
          path={__r('routes:clanky/kuchyna', '/clanky/kuchyna')}
          component={CMSArticles}
          props={{
            sitemapId: kitchenSubId,
            name: 'Kuchyňa',
          }}
        />
        <Route
          path={__r('routes:clanky/domacnost', '/clanky/domacnost')}
          component={CMSArticles}
          props={{
            sitemapId: homeSubId,
            name: 'Domácnosť',
          }}
        /> */}
        <Route
          path={`${__r('routes:tag', '/tag')}/:tag_name`}
          component={TagResults}
        />
        <Route path={'activation/:code'} component={renderActivation} />
        <Route path="**" component={UrlMapper} />

        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
